<template>
  <div class="UserHome">
    <div class="bottomDiv1" style="width: 90%; height: auto">
      <div class="index_title" style="border-bottom: none">统计分析</div>
      <div class="statistics " style="border-bottom: 3px #F0F0F0 solid;">
        <div>
          <div>
            <router-link to="/mobile_index/device_manage" class="item">
              <p class="s_title">充电站数</p>
              <p class="s_water">{{ FormData1.length_station }}</p>
            </router-link>
            </div>
          </div>
          <div>
            <div>
              <router-link to="/mobile_index/user_search" class="item">
                <p class="s_title">用户总数</p>
                <p class="s_water">{{ FormData1.number_of_ser }}</p>
              </router-link>
            </div>
          </div>
          <div>
            <div>
              <router-link to="/mobile_index/user_statics" class="item">
                <p class="s_title">充电次数</p>
                <p class="s_water">{{ FormData1.employ }}</p>
              </router-link>
            </div>
          </div>
          <div>
            <div>
              <router-link to="/mobile_index/invest_collect" class="item">
                <p class="s_title">充值次数</p>
                <p class="s_water">{{ FormData1.profit }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <el-divider border-style="double" />
      <div class="bottomDiv1" style="height: auto;">
      <div class="index_title" style="border-bottom: none">充电站列表</div>
      <div>
          <el-form  style="width:50vw; margin-left: 5vw;">
                <el-select v-model="FormData.type_id" placeholder="请选择充电车型">
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                </el-select>
              </el-form>
          <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
            <el-form-item style="width:50vw; margin-left: 5vw;">
              <el-input
                v-model="FormData.station_number"
                placeholder="请输入充电站编号"
              ></el-input>
            </el-form-item>
        
            <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
        </el-form>
      </div>
      <!-- <div v-for="item in tableData " :key="item">
        <div class="orderlist">
          <p>{{item.order_id}}</p>
        </div>
      </div> -->
      <div class="tableDiv">
          <el-table
        :data="tableData"
            border
            v-loading="loading"
            style="
              width: 90%; 
              margin: 20px auto;
              
              overflow-y: auto;
            "
        >
        <el-table-column label="编号" prop="station_number"/>
        <el-table-column label="充电类型" prop="type_id">
              <template #default="scope">
                <span v-if="scope.row.type_id == 2">电动车</span>
                <span v-if="scope.row.type_id == 4">电动汽车</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="手机号" prop="phone" />
            <el-table-column label="设备编号" prop="device_id" />
            <el-table-column label="充电站名称" prop="station_name" />
           
            <el-table-column label="充电端口" prop="socket" width="80" /> -->
            <el-table-column
            label="详情"
            align="center"
          >
            <template #default="scope" >
              <div style="float: left;">
                <div class="BtnSearch" @click="todetal(scope.$index, scope.row)">详情</div>
              </div>
              
            </template>
          </el-table-column>
            <!-- <el-table-column
            label="操作"
            align="center"
            width="120"
          >
            <template #default="scope" >
              <div>
                <div v-show="scope.row.is_return == 0" class="BtnSearch1 BtnSearch" @click="handleOp(scope.$index, scope.row)">开启退费</div>
                <div v-show="scope.row.is_return == 1" class="BtnSearch1 btnclose" @click="handleCl(scope.$index, scope.row)">关闭退费</div>
              </div>
              
            </template>
          </el-table-column> -->
           
          </el-table>
          <el-dialog
            v-model="Dialog.editLevy"
            title="充电站详情"
            width="80%"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                label-position="right"
                label-width="100px"
                style="font-size: 16px;"
              >
                <p>充电站编号: {{ Dialog.DialogData.station_number }}</p>
                <p>充电站名称: {{ Dialog.DialogData.station_name }}</p>
                <p>省: {{ Dialog.DialogData.province }}</p>
                <p>市: {{ Dialog.DialogData.city }}</p>
                <p>县/区: {{ Dialog.DialogData.county }}</p>
                <p>详细地址: {{ Dialog.DialogData.station_addr }}{{ Dialog.DialogData.community }}</p>
                <p>计费模板: {{ Dialog.DialogData.price_name }}</p>
                <p>充电类型: {{ Dialog.DialogData.type_id == '2'?'电动车':'电动汽车' }}</p>
              
              
              </el-form>
            </div>
          </el-dialog>
        </div>
    </div>
    </div>

  <!-- </div> -->
</template>

<style scoped>
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.UserHome {
  padding: 2px 3px 2px 2px;
}


.bottomDiv1 {
  background-color: #fff;
  padding: 20px;
}
.statistics {
  display: flex;
  width: 100%;
  margin: 0.1rem auto;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.warp {
  flex-wrap: wrap;
}

/* .statistics > .top1 {
  background-image: url("../../assets/img/five.png") !important;
  color: #29a1ec !important;
} */
.statistics > div {
  margin: 0.05rem;
  width: 22vw;
  height: 2rem;
  background-size: 100% 100%;
  text-align: center;
}
.statistics > div > div {
  margin: 0.5rem 0;
  cursor: pointer;
}
.statistics > div .s_title {
  font-size: 0.35rem;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div .s_water {
  font-size: 0.4rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
}
.warp > div {
  width: 2.5rem;
  height: 2rem;
}

</style>

<script>
// import { onMounted, reactive, toRefs, onBeforeUnmount, ref } from "vue-demi";
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { home_data_summary,sel_station  } from "@/request/api";
import * as echarts from "echarts";
// import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "UserHome",

    setup() {
    const data = reactive({
      //表单数据
      FormData: {
        operator_id : sessionStorage.getItem('userID'),
      },
      FormData1: {
        operator_id : sessionStorage.getItem('userID')
      },
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      total:'',
      total1:'',
      tableData: [
       
      ],
      loading: false,
     
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

    });
    

    const tonews = () =>{
      
      // data.$router.push({path: "/news/fault_news"})
    }
    const searchBtn = () => {
      
      data.loading = true;
      sel_station(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getList = () => {
      const dataa = {
            username: localStorage.getItem("username")
          }
          home_data_summary(dataa).then((res) => {
            if (res) {
              data.FormData1 = res.data
            }
          });
        }
    //   data.loading = true;
    //   sel_station(data.FormData).then((res) => {
    //     console.log(res);
    //     if (res) {
    //       data.loading = false;
    //       if (res.code == 200) {
    //         data.tableData = res.data;
    //         data.total = res.data.length;
    //       } else {
    //         ElMessage.error(res.msg);
    //       }
    //     } else {
    //       ElMessage.error("数据获取失败");
    //     }
    //   });

    //   sel_device(data.FormData).then((res) => {
    //     console.log(res);
    //     if (res) {
    //       data.loading = false;
    //       if (res.code == 200) {
    //         data.total1 = res.data.length;
    //       } else {
    //         ElMessage.error(res.msg);
    //       }
    //     } else {
    //       ElMessage.error("数据获取失败");
    //     }
    //   });
    // };

      const todetal = (index,row) => {

        data.Dialog.editLevy = true;
        data.Dialog.DialogData = row;
    }
  
    // const handleOp = (index,row)=>{
    //   const dataa = {
    //     id:row.id,
    //     is_return:1
    //   }
    //   return_switch(dataa).then((res)=>{
    //     if (res) {
    //       ElMessage.success("开启成功");
    //       getList()
    //     } else {
    //       ElMessage.error("开启失败");
    //     }
    //   })
      
    // }
    // const handleCl = (index,row)=>{
    //   const dataa = {
    //     id:row.id,
    //     is_return:0
    //   }
    //   return_switch(dataa).then((res)=>{
    //     if (res) {
    //       ElMessage.success("关闭成功");
    //       getList()
    //     } else {
    //       ElMessage.error("关闭失败");
    //     }
    //   })
    // }
 
    onMounted(() => {
      getList();
      searchBtn()
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      todetal,
      tonews
      // handleOp,
      // handleCl
    };
  },
}
  


</script>
